import placeholderImg from "../images/placeholder.jpg"; // Tell Webpack this JS file uses this image
import TestMax from "../images/pixellab--graphic-design--01--600x450.jpg"; // Tell Webpack this JS file uses this image
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

// Framer Motion – motion
import { motion } from "framer-motion";
import { preloader, page_transition } from "../components/Animations";

// Project: Data
// We need to access that Array in multiple files, such the "Project.js" template file and the "Main.js" file as a component
// to then filter by categories (graphic/web design)







// varriables
let currentPath;


// custom hook to get the current pathname in React
const usePathname = () => {
  const location = useLocation();
  currentPath = location.pathname;
  console.log("current path: " + currentPath);
  return currentPath;
}








let current_id = 1;

export const projectList = [
  {
    id: current_id++,
    category: 'Logo Design',
    title: 'Max',
    path: 'project-1/',
    img: {
      alt: 'Pixellab',
      title: 'Pixellab',
      img_url_thumbnail: require('../images/pixellab--graphic-design--02--600x450.jpg').default,
      img_url_list: [
        require('../images/pixellab--graphic-design--02--1200x900.jpg').default,
        // require('../images/pixellab--graphic-design--02--1200x900.jpg').default,
      ],
    },
    single_page__section_1: {
      project_type: 'Logo Design',
      headline: 'MAX',
      description:
        'Max is my personal Logo, which I created around 2013. I like looking at it and to use it for some of my profile pics around the internet. It reminds me of my journey of my graphic design journey and serves as a reminder to pursue my goals further.',
    },
    single_page__section_2: {
      headline: '',
      content: '',
      infobox: 'no',
    },
  },
  {
    id: current_id++,
    category: 'Logo Design',
    title: 'Reittherapie',
    path: 'project-2/',
    img: {
      alt: 'Pixellab',
      title: 'Pixellab',
      img_url_thumbnail: require('../images/pixellab--graphic-design--03--600x450.jpg').default,
      img_url_list: [require('../images/pixellab--graphic-design--03--1200x900.jpg').default],
    },
    single_page__section_1: {
      project_type: 'Logo Design',
      headline: 'Reittherapie Lea Issleib',
      description: 'Reittherapie Lea Issleib is a Logo for a small riding therapy business. The goal was to create a filigree Logo which resembles a horse.',
    },
    single_page__section_2: {
      headline: '',
      content: 'lorem ipsum content',
      infobox: 'no',
    },
  },
  {
    id: current_id++,
    category: 'Graphic Design',
    title: 'Survival of the Fittest',
    path: 'project-3/',
    test: '<a href="#">22</a>',
    img: {
      alt: 'Pixellab',
      title: 'Pixellab',
      img_url_thumbnail: require('../images/pixellab--graphic-design--04--600x450.jpg').default,
      img_url_list: [require('../images/pixellab--graphic-design--04--1200x900.jpg').default],
    },
    single_page__section_1: {
      project_type: 'Graphic Design',
      headline: 'Survival of the Fittest',
      description: 'Survival of the Fittest is an album cover, booklet and CD design for the musician Piece of Mind.',
    },
    single_page__section_2: {
      headline: 'More about the artist?',
      content: `If you are interested in the artist or his music, you can check out his social media here!`,
      infobox: 'no',
      infobox: 'yes',
      infobox_link_website: '#',
      infobox_link_instagram: 'https://www.instagram.com/_pieceofmind__/',
      infobox_link_facebook: 'https://www.facebook.com/profile.php?id=100063604991144',
    },
  },
  {
    id: current_id++,
    category: 'Graphic Design',
    title: 'Pustbank',
    path: 'project-4/',
    img: {
      alt: 'Pixellab',
      title: 'Pixellab',
      img_url_thumbnail: require('../images/pixellab--graphic-design--01--600x450.jpg').default,
      img_url_list: [require('../images/pixellab--graphic-design--01--1200x900.jpg').default],
    },
    single_page__section_1: {
      project_type: 'Graphic Design',
      headline: 'Pustbank',
      description: 'Pustbank is a flyer design for a fictional bank, inspired by the German bank "Postbank".',
    },
    single_page__section_2: {
      headline: '',
      content: '',
      infobox: 'no',
    },
  },
  {
    id: current_id++,
    category: 'Web Design',
    title: 'Vector Vision',
    path: 'project-5/',
    img: {
      alt: 'Pixellab',
      title: 'Pixellab',
      img_url_thumbnail: require('../images/pixellab--web-design--01a--600x450.jpg').default,
      img_url_list: [require('../images/pixellab--web-design--01a--1200x900.jpg').default],
    },
    single_page__section_1: {
      project_type: 'Web Design',
      headline: 'Vector Vision',
      description: 'Vector Vision is a fully responsive and custom designed agency website.',
    },
    single_page__section_2: {
      headline: '',
      content: '',
      infobox: 'no',
    },
  },
  {
    id: current_id++,
    category: 'Web Design',
    title: 'Pixellab Graphics',
    path: 'project-6/',
    img: {
      alt: 'Pixellab',
      title: 'Pixellab',
      img_url_thumbnail: require('../images/pixellab--web-design--02a--600x450.jpg').default,
      img_url_list: [require('../images/pixellab--web-design--02a--1200x900.jpg').default],
    },
    single_page__section_1: {
      project_type: 'Web Design',
      headline: 'Pixellab Graphics',
      description: 'Pixellab is the current website you are browsing now. Pixellab is my personal and latest website to showcase my work/projects.',
    },
    single_page__section_2: {
      headline: 'About THE project',
      content: "Pixellab Graphics is a personal portfolio website built with React.js. The website uses dynamic routing, dynamic content rendering and very little static elements. Besides that, the website was built with custom HTML (JSX), CSS (SCSS – BEM) and basic functional React components. More details in some future.",
      infobox: 'no',
    },
  },
  {
    id: current_id++,
    category: 'Web Design',
    title: 'Webdesign Bad Hersfeld',
    path: 'project-7/',
    img: {
      alt: 'Pixellab',
      title: 'Pixellab',
      img_url_thumbnail: require('../images/pixellab--web-design--03a--600x450.jpg').default,
      img_url_list: [require('../images/pixellab--web-design--03a--1200x900.jpg').default],
    },
    single_page__section_1: {
      project_type: 'Web Design',
      headline: 'Webdesign Bad Hersfeld',
      description: 'Webdesign Bad Hersfeld is a SEO optimized agency website for WordPress Web Design.',
    },
    single_page__section_2: {
      headline: '',
      content: '',
      infobox: 'no',
    },
  },
  {
    id: current_id++,
    category: 'Web Design',
    title: 'Mondklar',
    path: 'project-8/',
    img: {
      alt: 'Pixellab',
      title: 'Pixellab',
      img_url_thumbnail: require('../images/pixellab--web-design--04a--600x450.jpg').default,
      img_url_list: [require('../images/pixellab--web-design--04a--1200x900.jpg').default],
    },
    single_page__section_1: {
      project_type: 'Web Design',
      headline: 'Mondklar',
      description: 'Mondklar is a brand website for personalized Merch designs.',
    },
    single_page__section_2: {
      headline: '',
      content: '',
      infobox: 'no',
    },
  },
  {
    id: current_id++,
    category: 'Web Design',
    title: 'Raumeffekt',
    path: 'project-9/',
    img: {
      alt: 'Pixellab',
      title: 'Pixellab',
      img_url_thumbnail: require('../images/pixellab--web-design--05a--600x450.jpg').default,
      img_url_list: [require('../images/pixellab--web-design--05a--1200x900.jpg').default],
    },
    single_page__section_1: {
      project_type: 'Web Design',
      headline: 'Raumeffekt',
      description: 'Raumeffekt is a website for an architecture office, which focuses at 3D renderings and visualization.',
    },
    single_page__section_2: {
      headline: '',
      content: '',
      infobox: 'no',
    },
  },
];

const projects_graphic_design = projectList.filter(function (project) {
  return project.category === "Graphic Design";
});


const test = <GetProject title={projectList[1].title} img={projectList[1].img} />;

// Local Component
function GetProject(props) {
  return (
    <div className="project-card">
      <Link className="project-card__wrap" to={`/project/${props.path}`}>
        <img className="project-card__img" src={props.img.img_url_thumbnail} alt={props.img.alt} />
        <div className="project-card__title">{props.title}</div>
        <div className="project-card__category">{props.category}</div>
      </Link>
    </div>
  );
}


// Dynamic Data for single posts
export const MyProject = ({ projectList }) => {
  // we get the URL LINK as the parameter and output it
  // but we need the index to cycle through the array to output the single posts
  // by not using any IDs to cycle through the index of an array, we instead filter by the "useParams" URL and map the content of the filtered array
  const { projectURL } = useParams();

  usePathname();

  return (
    <>
      <motion.div className="preloader" initial="preloader_initial" exit="preloader_exit" variants={preloader}></motion.div>
      <motion.div
        className="page-transition"
        initial="page_transition_initial"
        animate="page_transition_animate"
        exit="page_transition_exit"
        variants={page_transition}
      >
        <main page="project">
          <section className="section section--about section--a">
            <div className="container project-wrap">
              {/* <h1> {projectURL} </h1> */}

              {projectList
                /* without filtering we will return every single post */
                .filter((project) => project.path === projectURL + "/")
                .map((project, index) => (
                <>

                  <div className="project" key={index}>
                    <div className="project__images">
                      {project.img.img_url_list.length > 0 ? <img className="project__img" src={project.img.img_url_list[0]} alt={project.img.name} /> : ""}
                      {project.img.img_url_list.length > 1 ? <img className="project__img" src={project.img.img_url_list[1]} alt={project.img.name} /> : ""}
                      {project.img.img_url_list.length > 2 ? <img className="project__img" src={project.img.img_url_list[2]} alt={project.img.name} /> : ""}
                    </div>
                    <div className="project__description">
                      <h1 className="project__type tt--upper fs--h6 mb--150">{project.single_page__section_1.project_type}</h1>
                      <h2 className="project__title fs--h5 pb--100">{project.single_page__section_1.headline}</h2>
                      <p className="fs--p1">{project.single_page__section_1.description}</p>
                    </div>
                  </div>

                  {/* Additional Dynamic Content:
                  If we have some content (condition is met), we display the following 
                  */}
                  {project.single_page__section_2.headline.length > 0 ? (

                      <div className="project-info pt--400">


                          {project.single_page__section_2.headline.length > 0 ? 
                          <>

                            {project.single_page__section_2.infobox == 'yes' ? 
                            <>
                            <div className="project__infobox">

                              <div className="infobox__item headline">
                                <h5 className="fs--h6 pb--75">Links:</h5>
                              </div>

                              {project.single_page__section_2.infobox_link_website.length > 0 ? 
                                <div className="infobox__item">
                                <a href={project.single_page__section_2.infobox_link_website} target="_blank" rel="noreferrer">Website</a>
                                </div>
                              : ""}

                              {project.single_page__section_2.infobox_link_instagram.length > 0 ? 
                                <div className="infobox__item">
                                <a href={project.single_page__section_2.infobox_link_instagram} target="_blank" rel="noreferrer">Instagram</a>
                                </div>
                              : ""}

                              {project.single_page__section_2.infobox_link_facebook.length > 0 ? 
                                <div className="infobox__item">
                                <a href={project.single_page__section_2.infobox_link_facebook} target="_blank" rel="noreferrer">Facebook</a>
                                </div>
                              : ""}

                            </div>
                            </>
                            : ""}

                          <div className="project__info">
                            <h2 className="fs--h6 pb--75">{project.single_page__section_2.headline}</h2>
                            <p className="fs--p2">{project.single_page__section_2.content}</p>   
                          </div>

                          </> 
                          : "" }


                      </div>                  
                    ) : ( "" )
                  }


                  <hr/>


                  {  
                  projectList.map((item, index) => (
                    <>                          
                      {"/project/" + item.path === currentPath ?
                        <>
                          <div className="project-pagination">
                            {
                              index == 0 ? "" : <Link className="btn btn--1 btn--a prev" to={"/project/" + projectList[index-1].path}>Prev</Link>      
                            }
                            {
                                index < projectList.length-1 ? <Link className="btn btn--1 btn--a next" to={"/project/" + projectList[index+1].path}>Next</Link> : "" 
                            }
                          </div>
                        </>
                        : ""}
                    </>
                  ))
                  }


                </>
                ))}
            </div>
          </section>
        </main>
      </motion.div>
    </>
  );
};

// Global Component
const Projects = ({ projects }) => {
  // Return the component
  return (
    <>
      <div className="project-listing">
        {projectList.map((project) => (
          <GetProject title={project.title} category={project.category} path={project.path} img={project.img} key={project.id} id={project.id} />
        ))}
      </div>
    </>
  );
};

export default Projects;

/*
## Introduction to React Components and Props | React.js Tutorial #1
https://www.youtube.com/watch?v=00AkMN9IAAY


## Full React Tutorial #1 - Introduction
https://www.youtube.com/watch?v=j942wKiXFu8&list=PL4cUxeGkcC9gZD-Tvwfod2gaISzfRiP9d
https://www.youtube.com/watch?v=tHjxSVaj_wY
https://www.youtube.com/watch?v=PHaECbrKgs0
https://www.youtube.com/watch?v=-YpnB-zlkPU

## Full React Tutorial #17 - Fetching Data with useEffect
https://www.youtube.com/watch?v=qdCHEUaFhBk


## React Tutorial #3 - Props
https://www.youtube.com/watch?v=F7uBc1vS6Do&t=301s
https://www.youtube.com/watch?v=1TYObnD0RCA
https://www.youtube.com/watch?v=UEPnehFNfzI&t=70s

## React 16.12 Tutorial 13: props.children
https://www.youtube.com/watch?v=Sq0FoUPxj_c&t=80s


## ReactJS Tutorial - 4 - Components
https://www.youtube.com/watch?v=Y2hgEGPzTZY
https://www.youtube.com/watch?v=Cla1WwguArA


// JSON
https://www.youtube.com/watch?v=4fCr1IepJRw


https://www.youtube.com/watch?v=Dorf8i6lCuk&t=8s
*/







/* Layout anpassen wie:
https://www.anthonyboyd.graphics/mockups/2021-imac-mockup/

wir brauchen 2 content abschnitte und der 2te muss "position relate sein", sodass dieser den oberen sticky content beim runterscrollen aufschieben kann
*/


/*
const a = "yes";
const b = a.link("https://www.w3schools.com");
*/









/*
                  /** 
                  projectList.map((item, index) => (
                    <>                          
                      <br/>
                      {"/project/" + item.path === currentPath ? "yes" : "no"}
                      <br/>
                      {"(index: " + index + ") " + item.path }
                    </>
                  ))    
                  

                  projectList.map((item, index) => (
                    <>                          
                      {"/project/" + item.path === currentPath 
                        ?
                        <>
                          <Link to={"/project/" + projectList[index+1].path}>Next</Link>
                        </>
                        : 
                          "no"}
                      <br/>
                    </>
                  ))             

*/
