import { Link } from "react-router-dom";
import hero_image from "../images/hero-max.jpg"; // Tell Webpack this JS file uses this image
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";
import { BsHeartFill } from "react-icons/bs";

// Framer Motion – motion
import { motion } from "framer-motion";
import { preloader, page_transition } from "../components/Animations";

// Meta Data (React Hemlet)
import GetMetaData from "../components/GetMetaData";
import aboutMeta from "../metaData/aboutMeta.json";
import metaImg_about from "../images/meta-images/pixellab--og--about.jpg";


const About = () => {
  return (
    <>
      <GetMetaData
        ogSiteName={aboutMeta.ogSiteName}
        title={aboutMeta.title}
        ogTitle={aboutMeta.ogTitle}
        twitterTitle={aboutMeta.twitterTitle}
        description={aboutMeta.description}
        ogDescription={aboutMeta.ogDescription}
        twitterDescription={aboutMeta.twitterDescription}
        imageUrl={metaImg_about}
        canonical={aboutMeta.canonical}
      />

      <motion.div className="preloader" initial="preloader_initial" animate="preloader_animation" exit="preloader_exit" variants={preloader}></motion.div>
      <motion.div
        className="page-transition"
        initial="page_transition_initial"
        animate="page_transition_animate"
        exit="page_transition_exit"
        variants={page_transition}
      >
        <main page="about">
          <section className="section section--about section--a">
            <div className="container">
              <div className="wrapper wrapper--about">
                <div className="wrapper__content">
                  <span className="pre-heading pb--10"></span>
                  <h1 className="fs--h1 tt--upper pb--175">About</h1>
                  <p className="fs--p1 pb--175">
                    Hi I'm max and the person behind Pixellab Graphics. I created Pixellab Graphics to showcase some of my designs and to stay
                    motivated/active.
                  </p>
                  <p className="fs--p1 pb--250">
                    If you are interested in seeing some of my webdesign or logo design work, you can do it on my personal website <a className="link" href="https://maxkorn.de/">maxkorn.de</a>.

                  </p>
                  <Link className="btn btn--1 btn--a" to="/">
                    Back
                  </Link>
                </div>
                <div className="wrapper__placeholder"></div>
              </div>
            </div>
          </section>
        </main>
      </motion.div>
    </>
  );
};

export default About;
