import React from "react";

// Framer Motion – motion
import { motion } from "framer-motion";
import { preloader, page_transition } from "../components/Animations";

// Meta Data (React Hemlet)
import GetMetaData from "../components/GetMetaData";
import contactMeta from "../metaData/contactMeta.json";
import metaImg_contact from "../images/meta-images/pixellab--og--contact.jpg";

const Contact = () => {
  return (
    <>
      <GetMetaData
        ogSiteName={contactMeta.ogSiteName}
        title={contactMeta.title}
        ogTitle={contactMeta.ogTitle}
        twitterTitle={contactMeta.twitterTitle}
        description={contactMeta.description}
        ogDescription={contactMeta.ogDescription}
        twitterDescription={contactMeta.twitterDescription}
        imageUrl={metaImg_contact}
        canonical={contactMeta.canonical}
      />

      <motion.div className="preloader" initial="preloader_initial" animate="preloader_animation" exit="preloader_exit" variants={preloader}></motion.div>
      <motion.div
        className="page-transition"
        initial="page_transition_initial"
        animate="page_transition_animate"
        exit="page_transition_exit"
        variants={page_transition}
      >
        <main page="contact">
          <section className="section section--a">
            <div className="container">
              <div className="wrapper wrapper--content content--center">
                <h1 className="fs--h1 tt--upper pb--200">Contact Me</h1>
                <p className="fs--p1 pb--200">
                  The only way to contact me for now, is via you e-mail provider until I add the contact form into my website. So please be understanding.
                </p>
                <p className="fs--h5">
                  <a href="mailto:kontakt@maxkorn.de">kontakt@maxkorn.de</a>
                </p>
              </div>
            </div>
          </section>
        </main>
      </motion.div>
    </>
  );
};

export default Contact;
