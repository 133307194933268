import React, { useState, useEffect } from "react";
import { MenuItems } from "./MenuItems";
import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../images/logo--pixellab.svg"; // Import the logo as an SVG Component
import SocialLinks from "../SocialLinks";



export const Navbar = () => {

  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    return setIsOpen(!isOpen);
  };

  // If we click the logo, we want to set the state to false, so the mobile menu closes when we click on the logo (while it's open).
  // otherwise, we change the route, but the menu doesn't close and we don't get the user feedback someting actually happened.
  // If we click on all the other mobile menu links, we the menu closes and we change routes/pates = much better user expierence.
  const logoLink = () => {
    return setIsOpen(false);
  };

  { 
    // so we only hide the scrolling when the mobile menu is open
    if (window.innerWidth < 800) {
      if (isOpen) {
        console.log("max");
        document.querySelector("body").classList.add("nav-flow");
      } else {
        document.querySelector("body").classList.remove("nav-flow");
      }
    } 
  }

  return (
    <>
      <nav className="navbar">
          <div className="navbar-wrapper">
            <Link className="navbar__logo" onClick={logoLink} to="/">
              <Logo /> <span>PIXELLAB</span>
            </Link>
            <span className="navbar__icon" onClick={handleClick}>
              {isOpen ? <FaTimes /> : <FaBars />}
            </span>
            <ul className={isOpen  ? "navbar__menu active" : "navbar__menu"}>
              {MenuItems.map((item, index) => {
                return (
                  <li className={item.cNameItem} key={index}>
                    <Link className={item.cNameItemLink} onClick={handleClick} to={item.url}>
                      {item.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        <SocialLinks />       
      </nav>
    </>
  );
}

export default Navbar;

// https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
// we can do it purley through CSS
