import ProjectsPlaceholder from "../components/ProjectsPlaceholder";

// Framer Motion – motion
import { motion } from "framer-motion";
import { preloader, page_transition } from "../components/Animations";

// Meta Data (React Hemlet)
import GetMetaData from "../components/GetMetaData";
import projectsMeta from "../metaData/projectsMeta.json";

const Work = () => {
  return (
    <>
      <GetMetaData
        ogSiteName={projectsMeta.ogSiteName}
        title={projectsMeta.title}
        ogTitle={projectsMeta.ogTitle}
        twitterTitle={projectsMeta.twitterTitle}
        description={projectsMeta.description}
        ogDescription={projectsMeta.ogDescription}
        twitterDescription={projectsMeta.twitterDescription}
        canonical={projectsMeta.canonical}
      />

      <GetMetaData title={projectsMeta.title} description={projectsMeta.description} />
      <motion.div className="preloader" initial="preloader_initial" animate="preloader_animation" exit="preloader_exit" variants={preloader}></motion.div>
      <motion.div
        className="page-transition"
        initial="page_transition_initial"
        animate="page_transition_animate"
        exit="page_transition_exit"
        variants={page_transition}
      >
        <main page="projects">
          <section className="section section--a">
            <div className="container">
              <div className="content--center">
                <h1 className="fs--h1 tt--upper pb--450">Designs</h1>
                <ProjectsPlaceholder />
              </div>
            </div>
          </section>
        </main>
      </motion.div>
    </>
  );
};

export default Work;
