import React from "react";
import Bands from "./Bands";

class ExternalBandsCC extends React.Component {

  render() {

    const bandList2 = [
      {
        id: 0,
        band_name: "Slipknot",
      },
      {
        id: 1,
        band_name: "Limb Bizkit",
      },
      {
        id: 2,
        band_name: "Gojira",
      },
    ];

    return (
      <>
        <p style={{ textDecoration: "underline", fontWeight: "bold" }}>{"<ExternalBandsClassComponent/>"}</p>
        {bandList2.map((band) => <Bands band_name={band.band_name} key={band.id} />)}
      </>
    );
  }
}

export default ExternalBandsCC;



