import number_1 from "../images/design--dotted-raster--ffffff-clean--0.png"; // Tell Webpack this JS file uses this image


const SocialLinks = () => {
    return (
        <div className="project-listing-2">

            <div className="project-card"> <img src={number_1} alt="Halftone 0"></img> </div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--1.png').default} alt="Halftone 1"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--2.png').default} alt="Halftone 2"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--3.png').default} alt="Halftone 3"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--4.png').default} alt="Halftone 4"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--5.png').default} alt="Halftone 5"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--6.png').default} alt="Halftone 6"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--7.png').default} alt="Halftone 7"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--8.png').default} alt="Halftone 8"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--9.png').default} alt="Halftone 9"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--A.png').default} alt="Halftone A"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--B.png').default} alt="Halftone B"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--C.png').default} alt="Halftone C"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--D.png').default} alt="Halftone D"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--E.png').default} alt="Halftone E"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--F.png').default} alt="Halftone F"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--G.png').default} alt="Halftone G"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--H.png').default} alt="Halftone H"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--I.png').default} alt="Halftone I"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--J.png').default} alt="Halftone J"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--K.png').default} alt="Halftone K"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--L.png').default} alt="Halftone L"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--M.png').default} alt="Halftone M"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--N.png').default} alt="Halftone N"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--O.png').default} alt="Halftone O"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--P.png').default} alt="Halftone P"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--Q.png').default} alt="Halftone Q"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--R.png').default} alt="Halftone R"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--S.png').default} alt="Halftone s"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--T.png').default} alt="Halftone T"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--U.png').default} alt="Halftone U"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--V.png').default} alt="Halftone V"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--W.png').default} alt="Halftone W"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--X.png').default} alt="Halftone X"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--Y.png').default} alt="Halftone Y"></img></div>
            <div className="project-card"> <img src={require('../images/design--dotted-raster--ffffff-clean--Z.png').default} alt="Halftone Z"></img></div>

        </div>
    );
};

export default SocialLinks;

