import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../images/logo--pixellab.svg"; // Import the logo as an SVG Component
import Projects from "../components/Projects";
import ProjectsPlaceholder from "../components/ProjectsPlaceholder";

// Framer Motion – motion
import { motion } from "framer-motion";
import { preloader, page_transition } from "../components/Animations";

// Meta Data (React Hemlet)
import GetMetaData from "../components/GetMetaData";
import homeMeta from "../metaData/homeMeta.json";
import metaImg_home from "../images/meta-images/pixellab--og--home.jpg";


const Main = () => {
  return (
    <>
      <GetMetaData
        ogSiteName={homeMeta.ogSiteName}
        title={homeMeta.title}
        ogTitle={homeMeta.ogTitle}
        twitterTitle={homeMeta.twitterTitle}
        description={homeMeta.description}
        ogDescription={homeMeta.ogDescription}
        twitterDescription={homeMeta.twitterDescription}
        imageUrl={metaImg_home}
        canonical={homeMeta.canonical}
      />

      <motion.div
        className="preloader"
        initial="preloader_initial" animate="preloader_animation" exit="preloader_exit" variants={preloader}>
      </motion.div>

      <motion.div className="page-transition"
        initial="page_transition_initial" animate="page_transition_animate" exit="page_transition_exit" variants={page_transition}>


        <main page="home">
          <section className="section section--a">
            <div className="container">
              <div className="wrapper wrapper--content content--center">
                <Logo className="logo--landing pb--125" alt="Pixellab Logo" />
                <span className="sub-heading pb--50">Welcome to</span>
                <h1 className="fs--h1 tt--upper pb--200">Pixellab Graphics</h1>
                <p className="fs--p1 pb--300">
                  Hello I'm Max the creator of Pixellab Graphics. If you are looking for an web developer/designer you might have found one with me.
                </p>
                <Link className="btn btn--1 btn--a" to="/contact">
                  Contact Me
                </Link>
              </div>
            </div>
          </section>

          <section className="section section--a">
            <div className="container">
              <div className="content--center">
                <h2 className="fs--h2 tt--upper pb--450">Designs</h2>

                {/*<Projects />*/}
                <ProjectsPlaceholder />

              </div>
            </div>
          </section>

          {/* make the qutes later dynamic. Make a list with quotes I like. */}
          <div className="quote">
            <blockquote> “Perfection is the enemy of progress.” </blockquote>
            <cite>Winston Churchill</cite>
          </div>

        </main>


      </motion.div>

    </>
  );
};

export default Main;


/*
    <main page="home">
      <section className="section section--a">
        <div className="container">
          <div className="wrapper wrapper--content content--center">
            <Logo className="logo--landing pb--125" alt="Pixellab Logo"/>
            <span className="sub-heading pb--50">Welcome to</span>
            <h1 className="fs--h1 tt--upper pb--200">Pixellab Graphics</h1>
            <p className="fs--p1 pb--300">
              Hello I’m Pixellab Graphics and I’m a frontend web developer. I design and create websites. Please feel free to check some of my testData out
              and if you are interested to work with me, don’t hesitate to contact me.
            </p>
            <Link className="btn btn--1 btn--a" to="/contact">
              Contact Me
            </Link>
          </div>
        </div>
      </section>
      
      <SocialLinks />

      <section className="section section--a">
        <div className="container">
          <div className="content--center">
            <h2 className="fs--h2 tt--upper pb--450">Projects</h2>
            <Projects />
          </div>
        </div>
      </section>

    </main>
*/