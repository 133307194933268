import { Link } from "react-router-dom";

const users = [
  {
    name: `Param`,
  },
  {
    name: `Vennila`,
  },
  {
    name: `Afrin`,
  },
];

export const Users = () => {
  return (
    <main page="userspage">
      <section className="section section--about section--a">
        <div className="container">
          <div className="wrapper wrapper--userspage">
            {users.map((user, index) => (
              <h5 key={index}>
                <Link to={`/user/${index + 1}`}>{user.name}'s Page</Link>
              </h5>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export const User = ({ match, location }) => {
  const {
    params: { userId },
  } = match;

  return (
    <main page="userspage">
      <section className="section section--about section--a">
        <div className="container">
          <div className="wrapper wrapper--userspage">
            <p>
              <strong>User ID: </strong>
              {userId}
            </p>
            <p>
              <strong>User Name: </strong>
              {users[userId - 1].name}
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

// https://codesandbox.io/s/p3ozwy018j?from-embed=&file=/src/index.js

// https://www.youtube.com/watch?v=NkT2yiv-NZ4

// https://www.youtube.com/watch?v=BLbTGKUzND4