import Bands from "./Bands";

const ExternalBands = () => {

    const bandList3 = [
        {
          id: 0,
          band_name: "Slipknot",
        },
        {
          id: 1,
          band_name: "Limb Bizkit",
        },
        {
          id: 2,
          band_name: "Gojira",
        },
      ];

    return (
        <>
          <p style={{ textDecoration: "underline", fontWeight: "bold" }}>{"<ExternalBandsFunctionComponent/>"}</p>
          {bandList3.map((band) => <Bands band_name={band.band_name} key={band.id} />)}
        </>
      );
  };

export default ExternalBands;