import { Link } from "react-router-dom";
import Bands from "./Bands";
import ExternalBandsClassComponent from "./ExternalBandsCC";
import ExternalBandsFunctionComponent from "./ExternalBandsFC";

import { bandList4 } from "./ExternalVariables";

const ReturnDynamicData = () => {
  // Access Data from internal Array (of Objects)
  const bandList1 = [
    {
      id: 0,
      band_name: "Slipknot",
    },
    {
      id: 1,
      band_name: "Limb Bizkit",
    },
    {
      id: 2,
      band_name: "Gojira",
    },
  ];

  // Alternative way to output the entire Arrays within one variable
  const bandList1_alternative = bandList1.map((band) => <Bands band_name={band.band_name} key={band.id} />);

  // Internal functional component
  function BandsInternal(props) {
    return (
      <>
        <p>Band Name: {props.band_name}</p>
      </>
    );
  }

  return (
    <>
      <section className="section section--a">
        <div className="container">
          <div className="wrapper content--center">
            <h1 className="testing-heading pb--40" style={{ fontSize: "32px" }}>
              Return Dynamic Data
            </h1>
            <Link className="btn btn--a" to="/">
              Back
            </Link>
          </div>
        </div>
      </section>
      <section className="section section--a">
        <div className="container">
          <div className="wrapper content--center">
            <h2 className="testing-heading pb--20" style={{ fontSize: "24px" }}>
              Bands
            </h2>
            <Bands band_name="Korn" />
            <p> {bandList1[0].band_name} </p>
            <hr />
            <p style={{ textDecoration: "underline", fontWeight: "bold" }}>{"<Bands/>"}</p>
            {bandList1.map((band) => (
              <Bands band_name={band.band_name} key={band.id} />
            ))}
            <hr />
            <p style={{ textDecoration: "underline", fontWeight: "bold" }}>{"{bandList1_alternative}"}</p>
            {bandList1_alternative}
            <hr />
            <p style={{ textDecoration: "underline", fontWeight: "bold" }}>{"<BandsInternal/>"}</p>
            {bandList1.map((band) => (
              <BandsInternal band_name={band.band_name} key={band.id} />
            ))}
            <hr />
            <ExternalBandsClassComponent />
            <hr />
            <ExternalBandsFunctionComponent />
            <hr />
            <p style={{ textDecoration: "underline", fontWeight: "bold" }}>ExternalArray</p>
            {bandList4.map((band) => (
              <BandsInternal band_name={band.band_name} key={band.id} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ReturnDynamicData;
