// Meta Data (React Hemlet)
import GetMetaData from "../components/GetMetaData";
import pageNotFoundMeta from "../metaData/pageNotFoundMeta.json";

const NotFound = () => {
  return (
    <>
      <GetMetaData       
      ogSiteName={pageNotFoundMeta.ogSiteName} 
      title={pageNotFoundMeta.title} 
      ogTitle={pageNotFoundMeta.ogTitle}   
      twitterTitle={pageNotFoundMeta.twitterTitle}          
      description={pageNotFoundMeta.description} 
      ogDescription={pageNotFoundMeta.ogDescription} 
      twitterDescription={pageNotFoundMeta.twitterDescription}  
      />

      <main page="not-found">
        <section className="section section--a">
          <div className="container">
            <div className="content--center">
              <h1 className="glitch" data-title="404">
                404
              </h1>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default NotFound;
