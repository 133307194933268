export const MenuItems = [
    {
        title: 'About',
        url: '/about/',
        cNameItem: 'navbar__menu__item',
        cNameItemLink: 'navbar__menu__item-link'
    },
    {
        title: 'Designs',
        url: '/designs/',
        cNameItem: 'navbar__menu__item',
        cNameItemLink: 'navbar__menu__item-link'
    },
    {
        title: 'Contact',
        url: '/contact/',
        cNameItem: 'navbar__menu__item',
        cNameItemLink: 'navbar__menu__item-link'
    }
]

/*
 
{
    title: 'Support',
    url: '/support/',
    cNameItem: 'navbar__menu__item',
    cNameItemLink: 'navbar__menu__item-link'
},

*/