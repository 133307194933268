import { Link } from "react-router-dom";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";

const SocialLinks = () => {
  return (
    <div className="socials">
      <a className="socials__link" href="https://www.instagram.com/kornform/" target="_blank" rel="noopener">
        <AiFillInstagram className="sm-instagram" />
      </a>
      <a className="socials__link" href="https://www.linkedin.com/in/kornform/" target="_blank" rel="noopener">
        <IoLogoLinkedin className="sm-linkedin" />
      </a>
      <a className="socials__link" href="https://www.facebook.com/maxkorn.de" target="_blank" rel="noopener">
        <AiFillFacebook className="sm-facebook" />
      </a>
    </div>
  );
};

export default SocialLinks;
