import React from "react";

class Bands extends React.Component {
  
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      key: props.key,
      band_name: props.band_name,
    };
  }

  render() {
    return (
      <div>
        <p>Band Name: {this.state.band_name}</p>
      </div>
    );
  }
}

export default Bands;
