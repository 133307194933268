import "./App.scss";
import { BrowserRouter as Router, Route, Switch, Redirect, Link, useLocation } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { homePath, aboutPath, imprintPath, privacyPath, projectsPath, ProjectsPlaceholderPath, contactPath, returndynamicdataPath } from "./components/routes";

import Navbar from "./components/Navbar/Navbar";

import Home from "./pages/Home--page";
import Projects from "./pages/Projects--page";
import ProjectsPlaceholder from "./pages/ProjectsPlaceholder--page";
import About from "./pages/About--page";
import Contact from "./pages/Contact--page";
import Imprint from "./pages/Imprint--page";
import Privacy from "./pages/Privacy--page";
import NotFound from "./pages/NotFound--page";

import Footer from "./components/Footer";

import { MyProject } from "./components/Projects";
import { projectList } from "./components/Projects";

// Framer Motion – AnimatePresence
import { AnimatePresence } from "framer-motion";

// This is for testing only
import ReturnDynamicData from "./testing/ReturnDynamicData";
import { Users, User } from "./testing/Users";

function App() {
  const location = useLocation();
  return (
    <>
      <Navbar />

      <AnimatePresence exitBeforeEnter initial={true}>
        {/* first we need to finish the animation, so the "scroll to top" doesn't look janky */}
        <ScrollToTop />

        <Switch location={location} key={location.pathname}>
          <Route exact path={homePath} component={Home} />
          <Route exact path={aboutPath} component={About} />
          <Route exact path={imprintPath} component={Imprint} />
          <Route exact path={privacyPath} component={Privacy} />
          {/*<Route exact path={projectsPath} component={Projects} />*/}
          <Route exact path={ProjectsPlaceholderPath} component={ProjectsPlaceholder} />
          <Route exact path={contactPath} component={Contact} />

          <Route exact path="/project/:projectURL/">
            <MyProject projectList={projectList} />
          </Route>


          {/* For testing only*/}
          <Route exact path={returndynamicdataPath} component={ReturnDynamicData} />
          <Route exact path="/user/" component={Users} />
          <Route exact path="/user/:userId" component={User} />

          {/* This needs to be the last component, so if no Route matches, we render the 404 page */}
          <Route component={NotFound} />
        </Switch>
      </AnimatePresence>

      <Footer />
    </>
  );
}

export default App;
