/* routes.js */

export const homePath = "/";
export const aboutPath = "/about/";
export const imprintPath = "/imprint/";
export const privacyPath = "/privacy/";
export const projectsPath = "/projects/";
export const ProjectsPlaceholderPath = "/designs/";
export const contactPath = "/contact/";

// Testing
export const returndynamicdataPath = "/returndynamicdata/";
export const dynamicDataPath = "/dynamicdata/";