export const bandList4 = [
    {
      id: 0,
      band_name: "Slipknot",
    },
    {
      id: 1,
      band_name: "Limb Bizkit",
    },
    {
      id: 2,
      band_name: "Gojira",
    },
  ];